import * as React from "react";
import "./OurFocusesSection.scss";
import howWeDoItImage from "../../assets/img/ourFocusesSectionAssets/EIW_HowWeDoIt_Image.png";
import ourFocusesTopLeftImageDesktop from "../../assets/img/patterns/Team_Roles_Top_Left_Pattern.svg";
import ourFocusesTopRightImageDesktop from "../../assets/img/patterns/Team_Roles_Top_Right_Pattern.svg";
import ourFocusesBottomLeftImageDesktop from "../../assets/img/ourFocusesSectionAssets/EIW_OurFocuses_Bottom_Left_Image_Desktop.svg";
import ourFocusesTopRightImageMobile from "../../assets/img/patterns/Team_Roles_Top_Right_Pattern_Mobile.svg";
import TitleWithAccents from "../TitleWithAccents/TitleWithAccents";
import Button from "../Button/Button";
import whyWeDoItImageDesktop from "../../assets/img/ourFocusesSectionAssets/EIW_WhyWeDoIt_Image_Desktop.png";
import whyWeDoItImageMobile from "../../assets/img/ourFocusesSectionAssets/EIW_WhyWeDoIt_Image_Mobile.png";
import AnimationButton from "../AnimationButton/AnimationButton";
import AwardsSection from "../AwardsSection/AwardsSection";
import AwardsCarousel from "../../components/AwardsCarousel/AwardsCarousel";

const OurFocusesSection = ({ content }) => {
  return (
    <div className="everyone-is-welcome-middle-section-container">
      <div className="how-we-do-it-container">
        <div>
          <h4>{content.howWeDoItTitle}</h4>
          <p>{content.howWeDoItDescription}</p>
          <p>{content.howWeDoItDescription1}</p>
          <p>{content.howWeDoItDescription2}</p>
        </div>
        <div>
          <img
            src={howWeDoItImage}
            aria-label="how-we-do-it-image"
            className="how-we-do-it-image"
          ></img>
        </div>
      </div>
      <div className="awards-section-container">
        <AwardsSection />
        </div>
      <div>
        <AwardsCarousel />
      </div>
      <div className="our-focuses-container">
        <img
          src={ourFocusesTopLeftImageDesktop}
          aria-label="our-focuses-top-left-image-desktop"
          className="our-focuses-top-left-image-desktop"
        ></img>
        <img
          src={ourFocusesTopRightImageDesktop}
          aria-label="our-focuses-top-right-image-desktop"
          className="our-focuses-top-right-image-desktop"
        ></img>
        <img
          src={ourFocusesTopRightImageMobile}
          aria-label="our-focuses-top-right-image-mobile"
          className="our-focuses-top-right-image-mobile mobile-only"
        ></img>
        <div className="our-focuses-title-with-accents">
          <TitleWithAccents title={content.ourFocusesTitle} />
        </div>
        <div className="our-focuses-grid-container">
          {content.focuses.map((focus, i) => {
            return (
              <div className="focuses-container" key={i}>
                {i > 0 && <hr />}
                <h3>{focus.name}</h3>
                <p>{focus.description}</p>
              </div>
            );
          })}
        </div>

        <Button
          label={content.focusButtonText}
          route={content.focusButtonLink}
          className="button our-focuses-button"
          target={"_blank"}
        />
        <img
          src={ourFocusesBottomLeftImageDesktop}
          aria-label="our-focuses-bottom-left-image-desktop"
          className="our-focuses-bottom-left-image-desktop"
        />
      </div>
      <div className="why-we-do-it-container">
        <div>
          <img
            src={whyWeDoItImageDesktop}
            aria-label="why-we-do-it-image-desktop"
            className="why-we-do-it-image-desktop"
          ></img>
        </div>
        <div className="why-we-do-it-text-container">
          <img
            src={whyWeDoItImageMobile}
            aria-label="why-we-do-it-image-mobile"
            className="why-we-do-it-image-mobile mobile-only"
          ></img>
          <h2>{content.whyWeDoItTitle}</h2>
          <p>{content.whyWeDoItDescription}</p>
          <AnimationButton label={content.buttonText} route={content.buttonLink} />
        </div>
      </div>
    </div>
  );
};

export default OurFocusesSection;
