export const OurFocusesSectionContent = {
  howWeDoItTitle: "How we do it…",
  howWeDoItDescription:
    "At Nando’s, we’re all about changing lives, together. That starts with our Nandocas. We deliberately work to create a safe environment where their voices are heard, they can really thrive and grow.",
  howWeDoItDescription1:
    "With people at our core, we actively address any barriers our current and future Nandocas face inside and outside Nando’s. We take responsibility and accountability of the role we play and need to continue to play in creating a fairer and equal society, for everyone. And by doing so, we inspire and encourage others to do the same.",
  howWeDoItDescription2:
    "Creating a sense of Inclusion and Belonging is essential for our people, our customers and our brand.",
  ourFocusesTitle: "Our focuses",
  whyWeDoItTitle: "Why we do it...",
  whyWeDoItDescription:
    "Everyone is Welcome is part of our DNA, it’s why people join our business and why they stay. Creating a respectful and inclusive culture is a key business priority for us, one that is imperative to our success. We are striving to drive inclusion for all and for the makeup of our business to be reflective of the Nandoca teams in our restaurants. Fancy joining us on the journey?",
  buttonText: "View Our Roles",
  buttonLink: "/our-roles",
  focuses: [
    {
      name: "Leadership",
      description:
        "We are committed to providing the right growth and development to lead inclusively.",
    },
    {
      name: "Data",
      description: "We responsibly collect, track and use data to inform our strategic priorities.",
    },
    {
      name: "Culture",
      description:
        "We create the conditions of an inclusive culture and a sense of belonging -  where people can be themselves, have a voice and feel appreciated for who they are.",
    },
    {
      name: "People Practices",
      description:
        "We build equitable and transparent processes and systems throughout the Nandoca lifecycle with inclusion at the heart.",
    },
  ],
  focusButtonText: "Gender Pay Gap Report",
  focusButtonLink: "https://www.nandos.co.uk/genderpayreport",
};
