import React from "react";
import Layout from "../../components/Layout/Layout";
import "./everyone-is-welcome.scss";
import SubPageTitle from "../../components/SubPageTitle/SubPageTitle";
import { everyoneIsWelcome } from "../../components/SubPageTitle/subPageTitleContent";
import rightPattern from "../../assets/img/patterns/EveryoneIsWelcomeRightPattern.svg";
import leftPattern from "../../assets/img/patterns/EveryoneIsWelcomeLeftPattern.svg";
import awardsImgLeftPattern from "../../assets/img/patterns/EveryoneIsWelcomeMobileAwardLeftPattern.svg";
import awardsImgRightPattern from "../../assets/img/patterns/EveryoneIsWelcomeMobileAwardRightPattern.svg";
import OurFocusesSection from "../../components/OurFocusesSection/OurFocusesSection.js";
import { OurFocusesSectionContent } from "../../components/OurFocusesSection/OurFocusesSectionContent.js";
import { SEO } from "../../components/seo";
import { descriptions } from "../../libs/seoContent";

const pageTitleBottomSection = () => {
  return (
    <>
      <div className="everyone-is-welcome-container">
        <h2>{everyoneIsWelcome.subTitleText}</h2>
        <div className="eiw-subtitle-content-container">
          <div className="eiw-img-container">
            <img
              src={everyoneIsWelcome.contentImage}
              alt="everyoneIsWelcomeSubtitle"
              className="eiw-content-img desktop-only"
            />
            <img
              src={everyoneIsWelcome.contentImageMobile}
              alt="everyoneIsWelcomeSubtitle"
              className="eiw-content-img-mobile mobile-only"
            />
          </div>
          <div className="eiw-text-content">
            <p>{everyoneIsWelcome.subTitleParagraph}</p>
            <p className="bottom-paragraph">{everyoneIsWelcome.subTitleParagraph1}</p>
          </div>
          <img
            src={leftPattern}
            aria-label="everyoneIsWelcomeLeftPattern"
            className="eiw-left-pattern no-pointer desktop-only"
          />
          <img
            src={rightPattern}
            aria-label="everyoneIsWelcomeRightPattern"
            className="eiw-right-pattern no-pointer desktop-only"
          />
        </div>
      </div>
    </>
  );
};

const EveryoneIsWelcome = () => {
  return (
    <Layout>
      <SubPageTitle content={everyoneIsWelcome} bottomSection={pageTitleBottomSection()} />
      <OurFocusesSection content={OurFocusesSectionContent} />
    </Layout>
  );
};

export default EveryoneIsWelcome;

export const Head = () => (
  <SEO title="Everyone is Welcome" description={descriptions.everyoneIsWelcome} />
);
